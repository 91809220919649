body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: scroll !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.termsNoMargin {
    margin: 0;
}
.termsP {
    font-size: 12px;
    margin-bottom: 5px;
}
.scrollToBottom {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-bottom: 15px;
    font-weight: 600;
}
.termsTitle {
    font-size: 14px;
    margin-bottom: 5px;
}
.termsTitle2 {
    font-size: 13px;
    margin-bottom: 5px;
}
.termsContainer {
    margin-bottom: 30px;
    margin-top: -10px;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.sigCanvas {
    outline: '1px solid red';
    background-color: #ededed;
}

.draggable-svg {
    cursor: move;
    user-select: none;
    height: '100%';
}

.resize-handle {
    border: 3px dotted #ccc;
    position: absolute;
    cursor: e-resize;
}
.resize-handle svg {
    width: 100% !important;
    height: 100% !important;
}

.Toastify__toast-container {
    z-index: 999999999999 !important; /* Adjust the value to suit your needs */
}
