.confetti-piece {
    position: absolute;
    width: 7px;
    height: 7px;
    animation: fall 4s;
    animation-iteration-count: once;
    opacity: 0;
}

@keyframes fall {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh) rotate(360deg);
        opacity: 0;
    }
}
